@self: cfs-query-result;

.@{self} {
  &__parallel-block {
    display: flex;
    justify-content: space-between;
    gap-column: 68px;

    .@{self}__block {
      padding-top: 0;
      border: 0;
    }
  }

  &__block {
    width: 100%;
    padding: 48px 12px;
    border-top: 1px dashed #F3F3F3;
    box-sizing: border-box;
  }

  &__block-label {
    line-height: 20px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 32px;

    &:before {
      content: '';
      width: 2px;
      height: 100%;
      border-left: 2px solid #3F6AE2;
      margin-right: 8px;
    }
  }

  &__block-content {
    &--row {
      display: flex;
      height: 36px;
      background: #F2F5F7;
      padding: 0 12px;
      align-items: center;
      justify-content: space-between;

      &:nth-child(2n) {
        background: #E8E8E8;
      }

      label {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
        font-size: 14px;
      }

      span {
        color: #000;
        text-align: right;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  &__list-table {
    width: 100%;

    td {
      color: #656565;
      text-align: center;
      font-size: 14px;
      font-weight: 400;

      a {
        color: #3F6AE2;
        text-decoration: underline;
      }
    }

    thead {
      tr {
        height: 32px;
        line-height: 32px;
        background: #EFEFEF;
        font-weight: 400;

        td {
          color: #9D9B9B;
        }
      }
    }

    tbody {
      tr {
        height: 64px;
        line-height: 64px;
        border-bottom: 1px solid #ECECEC;
      }
    }
  }

  &__block-nav {
    height: 38px;
    line-height: 38px;
    box-shadow: 0px 4px 10px 0px #0000000D;
    font-size: 14px;
    padding: 0 12px;
    margin-bottom: 32px;

    &--second {
      color: #A8A8A8;
    }
  }

  &__btns {
    width: 100%;
    padding: 0 12px;
    text-align: right;
    margin-top: -24px;
    margin-bottom: 48px;

    &.no-bottom-margin {
      margin-top: 24px;
      margin-bottom: 0;
    }

    &--btn {
      display: inline-block;
      width: 170px;
      height: 36px;
      line-height: 36px;
      color: #FFF;
      background: #3F6AE2;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;

      &.disabled {
        color: rgba(0, 0, 0, 0.2);
        background: #DFDFDF;
        cursor: not-allowed;
      }
    }
  }

  &__pay-processing {
    width: 90%;
    margin: 0 auto;

    &--nav {
      display: flex;
      width: 100%;
      margin: 0 auto;
      gap: 12px;
      margin-bottom: 32px;
    }

    &--nav-item {
      width: 33%;
      color: #B1ADAD;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 6px;
        margin-top: 8px;
        background: #E5E5E5;
      }

      &.active {
        color: #3F6AE2;

        &:after {
          background: #3F6AE2;
        }
      }
    }

    &--content {
      width: 90%;
      margin: 0 auto;
    }
  }
}

.go-to-pay {
  width: 90%;
  margin: 0 auto;
  margin-top: 64px;

  &__info {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  &__link {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-top: 18px;
    text-decoration: underline;
  }

  &__code-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
    margin-bottom: 64px;

    img {
      width: 160px;
    }

    &--info {
      font-size: 14px;
      font-weight: 400;

      b {
        color: #3F6AE2;
      }
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 12px;

    &--btn {
      width: 300px;
      border-radius: 4px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;

      &.confirm {
        color: #FFF;
        background: #2C6CE7;
      }

      &.back {
        border: 1px solid #6D7584;
        color: #6D7584;
      }
    }
  }
}

.confirm-payment {
  width: 90%;
  margin: 0 auto;

  &__form {
  }

  &__block {
    display: flex;
    width: 100%;
    column-gap: 24px;
  }

  &__full {
    width: 100%;
  }

  &__half {
    width: 50%;
  }

  &__uploader {
    width: 100%;

    .ant-form-item-control-input {
      border: 1px dashed #C1C4CF;
      border-radius: 4px;
      text-align: center;
    }

    &--placeholder {
      color: #3F6AE2;
      padding: 24px;
      cursor: pointer;
    }

    &--uploaded-file {
    }
  }

  &__btns {
    display: flex;
    width: 100%;
    margin-top: 32px;
    justify-content: center;
    column-gap: 24px;

    &--btn {
      display: inline-block;
      width: 170px;
      height: 36px;
      line-height: 36px;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;

      &.confirm {
        color: #FFF;
        background: #2C6CE7;
      }

      &.back {
        border: 1px solid #6D7584;
        color: #6D7584;
      }
    }
  }
}
