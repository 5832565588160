.cfs-client {
  display: flex;
  width: 930px;
  height: 100%;
  margin: 0 auto;
  padding: 32px 0 68px 0;
  box-sizing: border-box;
  flex-direction: column;

  &__top-bar {
    display: flex;
    width: 100%;
    padding: 20px 16px;
    border-raidus: 4px;
    background-color: #FFF;
    box-sizing: border-box;
    align-items: center;

    &--main {
      margin-left: 18px;
    }

    &--header {
      font-weight: 500;
      font-size: 18px;
    }

    &--content {
      font-weight: 400;
      font-size: 14px;
      color: #A8A8A8;
    }
  }

  &__main {
    display: flex;
    width: 100%;
    background-color: #FFF;
    margin-top: 24px;
    flex-grow: 1;
    flex-direction: column;

    &--top {
      display: flex;
      width: 100%;
      padding: 24px 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #F3F3F3;
      gap: 12px;
    }

    &--query-input {
      .ant-input-group-addon {
        background-color: #EFEFEF;
      }

      input.ant-input {
        background-color: #EFEFEF;
      }
    }

    &--query-btn {
      display: flex;
      width: 134px;
      background: #3F6AE2;
      border-radius: 4px;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      color: #FFF;
    }

    &--body {
      width: 100%;
      padding: 32px 20px;
      box-sizing: border-box;
      flex-grow: 1;
    }

    &--placeholder {
      display: flex;
      width: 100%;
      height: 100%;
      color: #EBEBEB;
      font-size: 168px;
      justify-content: center;
      align-items: center;
    }
  }
}
